
import { computed, useRoute } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';

import type { NavigationLinkProps } from '@/components/navigation/NavigationState';

import type {
  PageNavigationQuery,
  PageNavigationQueryVariables,
  Node,
  PageSubNavigationQuery,
  PageSubNavigationQueryVariables,
} from '@/graphql/generated';
import { pageNavigationQuery, subNavigationQuery } from '@/graphql/queries/pageNavigation';

export type PageNavigationNode = PageNavigationQuery['pageNavigation']

const pageNavNodeToNavLink = (node: Node): NavigationLinkProps => ({
  title: node.name,
  href: node.url,
  external: node.urlIsExternal,
  children: node.children?.map(child => pageNavNodeToNavLink(child)) || [],
});

export { NavigationLinkProps };

export const usePageNavigation = () => {
  const { result: primaryNavigationResult } = useQuery<PageNavigationQuery, PageNavigationQueryVariables>(pageNavigationQuery);
  const { result: secondaryNavigationResult } = useQuery<PageNavigationQuery, PageNavigationQueryVariables>(pageNavigationQuery, {
    scope: 'secondary_menu',
  });

  // Map to existing NavigationLinkProps interface from NavigationState.ts
  const primaryLinks = computed<NavigationLinkProps[]>(() => {
    const root = primaryNavigationResult.value?.pageNavigation;
    if (!root)
      return [];

    // Skip root node since Startpage is linked via logotype
    return root.children.map(child => pageNavNodeToNavLink(child as Node));
  });

  // Map to existing NavigationLinkProps interface from NavigationState.ts
  const secondaryLinks = computed(() => {
    const root = secondaryNavigationResult.value?.pageNavigation;
    if (!root)
      return [];

    // Skip root node
    return root.children.map(child => pageNavNodeToNavLink(child as Node));
  });

  return {
    primaryLinks,
    secondaryLinks,
  };
};

export const useSubNavigation = () => {
  const { path: url } = useRoute().value;
  const { result } = useQuery<PageSubNavigationQuery, PageSubNavigationQueryVariables>(subNavigationQuery, { url });

  const submenuLinks = computed(() => {
    const root = result.value?.pageSubNavigation;
    if (!root)
      return [];

    return root.children.map(child => pageNavNodeToNavLink(child as Node));
  });

  return {
    submenuLinks,
  };
};
