import { gql } from 'graphql-tag';

import { searchThumbImageFragment } from './image.fragments';

const pageSearchNodeFragment = gql`
  fragment PageSearchNode on Node {
    id
    contentType
    url
    metaProperties {
      titleShort
      description
      datePublished
      image {
        ...SearchThumbImageData
      }
    }
  }

  ${searchThumbImageFragment}
`;

export const pageSearchQuery = gql`
  query pageSearch ($query: String!) {
    pageSearch (query: $query) {
      ...PageSearchNode
    }
  }

  ${pageSearchNodeFragment}
`;
