/* eslint-disable import/no-duplicates */
import { format, intervalToDuration } from 'date-fns';
import sv from 'date-fns/locale/sv';
/* eslint-enable import/no-duplicates */

export const formatSv = (date: Date, formatStr: string) => format(date, formatStr, { locale: sv });

export const formatSimpleSv = (date: Date) => {
  const formattedDate = format(date, 'd MMM', { locale: sv }).split(' ');
  formattedDate[1] = formattedDate[1].substr(0, 3);
  return formattedDate.join(' ');
};
export const formatDurationSv = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  let str = '';
  if (duration.hours)
    str += `${duration.hours} tim`;

  if (duration.minutes)
    str += `${duration.minutes} min`;

  return str;
};
