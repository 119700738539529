import { gql } from 'graphql-tag';

export const globalAlertQuery = gql`
  query globalAlert {
    globalAlert {
      message
      linkText
      linkUrl
    }
  }
`;
