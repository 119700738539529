import { gql } from 'graphql-tag';

const pageNodeFragment = gql`
  fragment PageNavigationNode on Node {
    id
    name
    contentType
    url
    urlIsExternal
  }
`;

export const pageNavigationQuery = gql`
  query pageNavigation ($scope: String) {
    # Startpage
    pageNavigation (scope: $scope) {
      ...PageNavigationNode
      # Main navigation
      children {
        ...PageNavigationNode
        # Second level
        children {
          ...PageNavigationNode
        }
      }
    }
  }

  ${pageNodeFragment}
`;

const subPageNodeFragment = gql`
  fragment SubPageNavigationNode on Node {
    id
    name
    url
    urlIsExternal
  }
`;

export const subNavigationQuery = gql`
  query pageSubNavigation ($url: String!) {
    pageSubNavigation (url: $url) {
      ...SubPageNavigationNode
      children {
        ...SubPageNavigationNode
      }
    }
  }

  ${subPageNodeFragment}
`;
