
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Blockable": [
      "ArticleBlock",
      "ArticleListBlock",
      "AudioBlock",
      "FaqBlock",
      "FileBlock",
      "FormBlockBlock",
      "ImageBlock",
      "ImageGalleryBlock",
      "JohanDaleneAboutHeaderBlock",
      "JohanDaleneImageBlock",
      "JohanDaleneInlineArticlePushBlock",
      "JohanDaleneLatestNewsBlock",
      "JohanDaleneLinkButtonBlock",
      "JohanDaleneRecognitionBlock",
      "JohanDaleneReviewBlock",
      "JohanDaleneUpcomingConcertBlock",
      "MailchimpSubscribeBlock",
      "NewsListBlock",
      "NorrkopingAirportCharterBlock",
      "NorrkopingAirportDestinationsSliderBlock",
      "NorrkopingAirportDividerBlock",
      "NorrkopingAirportFeaturePushBlock",
      "NorrkopingAirportHeroBlock",
      "NorrkopingAirportImageBlock",
      "NorrkopingAirportLastMinuteBlock",
      "NorrkopingAirportLunchBlock",
      "NorrkopingAirportPageChildBlock",
      "NorrkopingAirportParkingBlock",
      "NorrkopingAirportPartnerBlock",
      "NorrkopingAirportStaffBlockBlock",
      "NorrkopingAirportTaxFreeFormBlock",
      "NorrkopingAirportVideoBlock",
      "NorrkopingAirportWysiwygFourColumnBlock",
      "QuickLinksBlockBlock",
      "ScenkonstArticleListLargeBlock",
      "ScenkonstCalendarBlock",
      "ScenkonstDigitalStageBlock",
      "ScenkonstInlineTermBlock",
      "ScenkonstOnStageBlock",
      "ScenkonstSponsorBlock",
      "ScenkonstSpotlightBlock",
      "ScenkonstSubscriptionsListBlock",
      "StadsmissionenArticleListBlock",
      "StadsmissionenEmbeddedContentBlock",
      "StadsmissionenNewsletterBlock",
      "StadsmissionenPartnerBlock",
      "StadsmissionenSpotlightBlock",
      "VideoBlock",
      "WysiwygBlock",
      "WysiwygTwoColumnBlock"
    ],
    "Contentable": [
      "JohanDaleneConcertContent",
      "JohanDaleneConcertListingContent",
      "JohanDaleneContactPageContent",
      "JohanDaleneMediaPageContent",
      "JohanDaleneRecordingContent",
      "JohanDaleneRecordingListingContent",
      "NewsArticleContent",
      "NewsListingContent",
      "NorrkopingAirportDestinationContent",
      "NorrkopingAirportDestinationListingContent",
      "NorrkopingAirportProductItemContent",
      "NorrkopingAirportProductListingContent",
      "ScenkonstInvitationContainerContent",
      "ScenkonstInvitationContent",
      "ScenkonstOtPerformanceContent",
      "ScenkonstOtPerformanceListingContent",
      "ScenkonstStaffCategorySingleListingContent",
      "ScenkonstStaffContent",
      "ScenkonstStaffListingContent",
      "ShortUrlContent",
      "StandardContent",
      "StartpageContent"
    ]
  }
};
      export default result;
    