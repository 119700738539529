import { useQuery } from '@vue/apollo-composable';
import { computed } from '@nuxtjs/composition-api';

import { keyValueStoreQuery } from '@/graphql/queries/keyValueStore';
import type { KeyValueStoreItemsQuery, KeyValueStoreItemsQueryVariables } from '@/graphql/generated';

export const useKeyValueStore = (id: string) => {
  const { result } = useQuery<KeyValueStoreItemsQuery, KeyValueStoreItemsQueryVariables>(keyValueStoreQuery, {
    id,
  });
  return computed(() => result.value?.keyValueStoreItems?.value);
};
