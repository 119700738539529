import { computed, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';

import { formatSv } from '@/composables/datefns';

import { debounce } from './utils';

import { pageSearchQuery } from '@/graphql/queries/pageSearch';
import type { PageSearchQuery, PageSearchQueryVariables, SearchThumbImageDataFragment } from '@/graphql/generated';

export interface SiteSearchResultItem {
  title: string;
  description: string;
  url: string;
  image: SearchThumbImageDataFragment;
  date?: string;
}

const searchQuery = ref(null);
const searchOpen = ref(false);

// Send to GTag
watch(searchQuery, debounce(() => searchQuery.value && window.gtag('event', 'search', { search_term: searchQuery.value }), 2000));

export const usePageSearch = () => {
  const minQueryLength = 3;

  const route = useRoute();
  if (searchQuery.value === null)
    searchQuery.value = route.value.query.search || '';

  if (searchQuery.value.length > 0)
    searchOpen.value = true;

  const { result, loading } = useQuery<PageSearchQuery, PageSearchQueryVariables>(
    pageSearchQuery,
    () => ({ query: searchQuery.value }),
    () => ({
      enabled: searchQuery.value.length >= minQueryLength,
      prefetch: false,
    }),
  );

  const categorizedResult = computed(() => {
    const performances = [];
    const news = [];
    const staff = [];
    const other = [];

    if (searchQuery.value.length >= minQueryLength)
      result.value?.pageSearch.forEach((pageNode) => {
        const page: SiteSearchResultItem = {
          title: pageNode.metaProperties.titleShort,
          description: pageNode.metaProperties.description,
          url: pageNode.url,
          image: pageNode.metaProperties.image,
        };

        switch (pageNode.contentType) {
          case 'ScenkonstOtPerformance':
            performances.push(page);
            break;
          case 'NewsArticle':
            page.date = formatSv(new Date(pageNode.metaProperties.datePublished), 'd MMM yyyy').replace('.', '');
            news.push(page);
            break;
          case 'ScenkonstStaff':
            staff.push(page);
            break;
          default:
            other.push(page);
        }
      });

    return [
      {
        title: 'Konserter',
        result: performances,
      },
      {
        title: 'Nyhetsartiklar',
        result: news,
      },
      {
        title: 'Medarbetare',
        result: staff,
      },
      {
        title: 'Sidor',
        result: other,
      },
    ];
  });

  return {
    pageSearchQuery: searchQuery,
    pageSearchResult: categorizedResult,
    pageSearchLoading: loading,
    pageSearchOpen: searchOpen,
  };
};
