import { gql } from 'graphql-tag';

const imageFragment = gql`
  fragment ImageData on Image {
    id
    altText
    dominantColor
  }
`;

const imageVariantFragment = gql`
  fragment ImageVariantData on ImageVariant {
    id
    width
    height
    ratio
    mimeType
    url
  }
`;

/**
 * Full-sized image
 */
export const fullSizedImageFragment = gql`
 fragment FullSizedImageData on Image {
   ...ImageData
   
    author
    copyright
    downloadUrl

   variant_lqip: variant(preset: lqip) { ...ImageVariantData } 

   variant_max_jpg: variant(format: jpg) { ...ImageVariantData }
   variant_1920_jpg: variant(width: 1920, format: jpg) { ...ImageVariantData }
   variant_1600_jpg: variant(width: 1600, format: jpg) { ...ImageVariantData }
   variant_1280_jpg: variant(width: 1280, format: jpg) { ...ImageVariantData }
   variant_720_jpg: variant(width: 720, format: jpg) { ...ImageVariantData }

   variant_max_webp: variant(format: webp) { ...ImageVariantData}
   variant_1920_webp: variant(width: 1920, format: webp) { ...ImageVariantData }
   variant_1600_webp: variant(width: 1600, format: webp) { ...ImageVariantData }
   variant_1280_webp: variant(width: 1280, format: webp) { ...ImageVariantData }
   variant_720_webp: variant(width: 720, format: webp) { ...ImageVariantData }
 }

 ${imageFragment}
 ${imageVariantFragment}
`;

/**
 * Large-sized image
 */
export const largeImageFragment = gql`
  fragment LargeImageData on Image {
    ...ImageData

    variant_16x9_lqip: variant(ratio: ratio_16x9, preset: lqip) { ...ImageVariantData } 

    variant_16x9_max_jpg: variant(ratio: ratio_16x9, format: jpg) { ...ImageVariantData }
    variant_16x9_1920_jpg: variant(width: 1920, ratio: ratio_16x9, format: jpg) { ...ImageVariantData }
    variant_16x9_1600_jpg: variant(width: 1600, ratio: ratio_16x9, format: jpg) { ...ImageVariantData }
    variant_16x9_1280_jpg: variant(width: 1280, ratio: ratio_16x9, format: jpg) { ...ImageVariantData }
    variant_16x9_720_jpg: variant(width: 720, ratio: ratio_16x9, format: jpg) { ...ImageVariantData }

    variant_16x9_max_webp: variant(ratio: ratio_16x9, format: webp) { ...ImageVariantData}
    variant_16x9_1920_webp: variant(width: 1920, ratio: ratio_16x9, format: webp) { ...ImageVariantData }
    variant_16x9_1600_webp: variant(width: 1600, ratio: ratio_16x9, format: webp) { ...ImageVariantData }
    variant_16x9_1280_webp: variant(width: 1280, ratio: ratio_16x9, format: webp) { ...ImageVariantData }
    variant_16x9_720_webp: variant(width: 720, ratio: ratio_16x9, format: webp) { ...ImageVariantData }
  }

  ${imageFragment}
  ${imageVariantFragment}
`;

/**
 * Medium-sized image 4x3
 */
export const medium4x3ImageFragment = gql`
 fragment Medium4x3ImageData on Image {
    id
    dominantColor

    variant_4x3_1280_jpg: variant(width: 1280, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_4x3_960_jpg: variant(width: 960, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_4x3_720_jpg: variant(width: 720, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_4x3_512_jpg: variant(width: 512, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_4x3_320_jpg: variant(width: 320, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }

    variant_4x3_1280_webp: variant(width: 1280, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_4x3_960_webp: variant(width: 960, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_4x3_720_webp: variant(width: 720, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_4x3_512_webp: variant(width: 512, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_4x3_320_webp: variant(width: 320, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
 }

 ${imageVariantFragment}
`;

/**
 * Medium-sized image 1x1
 */
export const medium1x1ImageFragment = gql`
 fragment Medium1x1ImageData on Image {
    id
    dominantColor

    variant_1x1_1280_jpg: variant(width: 1280, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_960_jpg: variant(width: 960, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_720_jpg: variant(width: 720, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_512_jpg: variant(width: 512, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_320_jpg: variant(width: 320, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }

    variant_1x1_1280_webp: variant(width: 1280, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_960_webp: variant(width: 960, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_720_webp: variant(width: 720, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_512_webp: variant(width: 512, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_320_webp: variant(width: 320, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
 }

 ${imageVariantFragment}
`;

/**
 * Thumbnail image
 */
export const thumb1x1ImageFragment = gql`
 fragment Thumb1x1ImageData on Image {
    id
    dominantColor

    variant_1x1_512_jpg: variant(width: 512, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_256_jpg: variant(width: 256, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_128_jpg: variant(width: 128, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }
    variant_1x1_64_jpg: variant(width: 64, ratio: ratio_1x1, format: jpg) { ...ImageVariantData }

    variant_1x1_512_webp: variant(width: 512, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_256_webp: variant(width: 256, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_128_webp: variant(width: 128, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
    variant_1x1_64_webp: variant(width: 64, ratio: ratio_1x1, format: webp) { ...ImageVariantData }
 }

 ${imageVariantFragment}
`;

/**
 * Search thumbnails
 */
export const searchThumbImageFragment = gql`
 fragment SearchThumbImageData on Image {
    id
    dominantColor

    variant_1x1_512_jpg: variant(width: 512, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_1x1_256_jpg: variant(width: 256, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_1x1_128_jpg: variant(width: 128, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }
    variant_1x1_64_jpg: variant(width: 64, ratio: ratio_4x3, format: jpg) { ...ImageVariantData }

    variant_1x1_512_webp: variant(width: 512, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_1x1_256_webp: variant(width: 256, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_1x1_128_webp: variant(width: 128, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
    variant_1x1_64_webp: variant(width: 64, ratio: ratio_4x3, format: webp) { ...ImageVariantData }
 }

 ${imageVariantFragment}
`;

/**
 * Digital stage block
 */
export const digitalStageImageFragment = gql`
 fragment DigitalStageImageData on Image {
   ...ImageData

   variant_lqip: variant(preset: lqip) { ...ImageVariantData } 

   variant_max_jpg: variant(format: jpg) { ...ImageVariantData }
   variant_1920_jpg: variant(width: 1920, format: jpg) { ...ImageVariantData }
   variant_1600_jpg: variant(width: 1600, format: jpg) { ...ImageVariantData }
   variant_1280_jpg: variant(width: 1280, format: jpg) { ...ImageVariantData }
   variant_720_jpg: variant(width: 720, format: jpg) { ...ImageVariantData }

   variant_max_webp: variant(format: webp) { ...ImageVariantData}
   variant_1920_webp: variant(width: 1920, format: webp) { ...ImageVariantData }
   variant_1600_webp: variant(width: 1600, format: webp) { ...ImageVariantData }
   variant_1280_webp: variant(width: 1280, format: webp) { ...ImageVariantData }
   variant_720_webp: variant(width: 720, format: webp) { ...ImageVariantData }
 }

 ${imageFragment}
 ${imageVariantFragment}
`;
