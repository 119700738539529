import { gql } from 'graphql-tag';

export const keyValueStoreQuery = gql`
  query keyValueStoreItems ($id: ID!) {
    keyValueStoreItems (id: $id) {
      id
      value
    }
  }
`;
