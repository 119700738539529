import { computed, ComputedRef } from '@nuxtjs/composition-api';

import type { Image, ImageVariant, ImageVariantDataFragment } from '@/graphql/generated';

/**
 * Convert GraphQL image to a more convenient format
 * @param image GraphQL image object
 */
export const graphQLImage = (image: Partial<Image> | null | undefined) => {
  if (!image)
    return null;

  const metadata = {
    id: image.id,
    altText: image.altText || null,
    author: image.author || null,
    copyright: image.copyright || null,
    dominantColor: image.dominantColor || null,
  };

  const lqipKey = 'lqip';
  const lqip = Object.entries(image as Record<string, ImageVariant>)
    .find(([key]) => key.includes(lqipKey))?.[1];

  const variants = Object.entries(image).reduce((images, [key, value]) => {
    if (key.startsWith('variant') && !key.includes(lqipKey))
      images.push(value as ImageVariantDataFragment);

    return images;
  }, [] as ImageVariantDataFragment[]);

  return {
    metadata,
    variants,
    lqip,
  };
};

/**
 * Convert GraphQL image to a more convenient format
 * @param image GraphQL image object
 */
export const useGraphQLImage = (image: ComputedRef<Partial<Image>>) => {
  return {
    image: computed(() => image.value),
  };
};

export type GraphQLImage = ReturnType<typeof useGraphQLImage>['image']['value'];
