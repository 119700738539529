import { computed } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';

import type { GlobalAlertQuery } from '@/graphql/generated';
import { globalAlertQuery } from '@/graphql/queries/globalAlert';

export const useGlobalAlert = () => {
  const { result, loading } = useQuery<GlobalAlertQuery>(globalAlertQuery);

  return {
    globalAlert: computed(() => result.value?.globalAlert || { message: null, linkText: '', linkUrl: '' }),
    globalAlertLoading: loading,
  };
};
